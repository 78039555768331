<template>
  <div class="pt-half">
    <div class="card card-psh border m-0">
      <div class="bg-light-success rounded-top py-half px-1">
        <div class="psh-header mb-0 d-flex align-items-center px-0">
          <div class="psh-title border-0 mr-0">
            <i class="bi bi-globe text-h1" style="font-size: 2rem;" />
          </div>
          <h4 class="card-title mb-25 text-success flex-grow-1">
            Customs Declaration
          </h4>
          <div>
            <strong class="text-button d-inline" role="button" @click="showDetails = !showDetails">
              Details
            </strong>
          </div>
          <div v-if="!isShipped" class="mx-1">
            <strong class="text-button d-inline" role="button" @click="saveCustomsDeclaration">
              Save
            </strong>
            </div>
        </div>
      </div>
      <div class="card-body" style="padding:6px;">
        <div class="rounded-bottom">
          <div>
            <div class="row px-1">
              <div class="col-12 col-md-4 px-half">
                <dx-util-select-box v-model="selectedContentOfType" :data-source="contentOfTypes" key-expr="value" display-expr="text" label="Purpose of Shipment" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-select-box v-model="selectedReturnHandling" :data-source="returnHandlingOptions" key-expr="value" display-expr="text" label="Return Handling" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-text-box v-model="signingPerson" label="Signing Person" />
              </div>
              <div v-show="referenceKey === 'LABEL_TOOL' || showDetails" class="col-12 px-half">
                <dx-util-text-box v-model="notes" label="Notes" />
              </div>
            </div>
            <div v-show="showDetails" class="row px-1">
              <div class="col-12 col-md-4 px-half">
                <dx-util-select-box v-model="selectedIncoterm" label="Incoterm" :data-source="incotermOptions" key-expr="value" display-expr="text" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-text-box v-model="importerReference" label="Importer Reference" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-text-box v-model="exporterReference" label="Exporter Reference" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-text-box v-model="eoriNumber" label="EORI Number" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-select-box v-model="taxIdType" label="Tax ID Type" :data-source="taxIdOptions" key-expr="value" display-expr="text" />
              </div>
              <div class="col-12 col-md-4 px-half">
                <dx-util-text-box v-model="taxIdNumber" label="Tax ID Number" />
              </div>
              <div class="col-12 py-half">
                <div>
                  <dx-util-check-box
                    v-model="isContainAlcohol"
                    class="m-half"
                    text="Is Contain Alcohol"
                  />
                  <dx-util-check-box
                    v-model="isContainDryIce"
                    class="m-half"
                    text="Is Contain Dry Ice"
                  />
                  <dx-util-check-box
                    v-model="isContainHazMat"
                    class="m-half"
                    text="Is Contain Hazardous Material"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="card mb-half">
              <div class="card-header pr-half pl-1 py-half bg-light-primary rounded my-half">
                <div class="flex-grow-1">
                  Customs Items
                </div>
                <div role="button" class="text-button mx-half" @click="openInventorySearch">
                  Add Item
                </div>
              </div>
              <div v-for="item in customsItems" :key="item.id" class="border-bottom py-half">
                <single-customs-item :item="item" @emit-update-item="handleUpdateItem" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <inventory-search :component-id="inventorySearchCompId" :store-id="storeId" @emit-product-selected="handleProductSelected" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
// import { confirm } from 'devextreme/ui/dialog'
import { getEnumValues } from '@/enums/internationalShipment.enum'
import shipmentLabelService from '@/http/requests/external/shipmentLabels'
import { Notify } from '@/@robustshell/utils'
import useCurrentUser from '@/libs/app/current-user'
import SingleCustomsItem from './SingleCustomsItem.vue'
import useCustomsItems from './useCustomsItems'

export default {
  components: {
    'single-customs-item': SingleCustomsItem,
    'inventory-search': () => import('@/views/apps/tools/components/InventorySearch.vue'),
  },
  props: {
    // In FBM, it is number, but in Label Tools it is free text for order numbers.
    referenceId: {
      type: [Number, String],
      default: null,
    },
    referenceKey: {
      type: String,
      default: 'LABEL_TOOL',
    },
    // isCurrentOrderShipped for FBM
    isShipped: {
      type: Boolean,
      default: false,
    },
    isInternational: {
      type: Boolean,
      default: false,
    },
    isFbmForce: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contentOfTypes: getEnumValues('PurposeOfShipment'),
      returnHandlingOptions: getEnumValues('ReturnHandling'),
      incotermOptions: getEnumValues('Incoterm'),
      taxIdOptions: getEnumValues('TaxIdType'),
      customsItems: [],
      selectedContentOfType: null,
      selectedReturnHandling: null,
      signingPerson: 'John Doe',
      showDetails: false,
      selectedIncoterm: 'DDU',
      importerReference: '',
      exporterReference: '',
      taxIdType: 'VAT',
      taxIdNumber: '',
      eoriNumber: '',
      isContainAlcohol: false,
      isContainDryIce: false,
      isContainHazMat: false,
      fbmInventorySearchCompId: '',
      notes: '',
      customsDeclaration: {
        typeOfContent: '',
        signingPerson: '',
        noneDeliveringHandling: '',
        items: {
          items: [],
        },
      },
      formSaved: '',
      inventorySearchCompId: '',
    }
  },
  setup() {
    const {
      customsItemsState, storeId, addCustomsItem, customsDeclarationId,
    } = useCustomsItems()
    const { userFullname } = useCurrentUser()
    return {
      customsItemsState, storeId, addCustomsItem, userFullname, customsDeclarationId,
    }
  },
  watch: {
    referenceId: {
      immediate: true,
      handler() {
        this.getCustomsDeclaration()
      },
    },
    customsItemsState: {
      immediate: true,
      deep: true,
      handler() {
        this.customsItems = this.customsItemsState
      },
    },
    // If the store changes to update the customs declaration
    storeId: {
      immediate: false,
      handler() {
        // if it is called from label form fetch data
        if (!this.isFbmForce) {
          this.getCustomsDeclaration()
        }
      },
    },
  },
  methods: {
    async getCustomsDeclaration() {
      if (this.referenceKey === 'LABEL_TOOL' && this.storeId === null) {
        return
      }
      // SET DEFAULTS FOR SHIPPING LABEL TOOL
      const refId = this.referenceId || this.storeId
      const refKey = this.referenceKey || 'LABEL_TOOL'
      this.customsDeclaration = await shipmentLabelService.getCustomsDeclaration(refKey, refId)
      this.selectedContentOfType = this.contentOfTypes.filter(el => el.value === this.customsDeclaration.typeOfContent)[0]
      this.selectedReturnHandling = this.returnHandlingOptions.filter(el => el.value === this.customsDeclaration.noneDeliveringHandling)[0]
      this.signingPerson = this.customsDeclaration.signingPerson
      this.selectedIncoterm = this.incotermOptions.filter(el => el.value === this.customsDeclaration.incoterm)[0] || this.incotermOptions[0]
      this.importerReference = this.customsDeclaration.importerReference
      this.exporterReference = this.customsDeclaration.exporterReference
      this.eoriNumber = this.customsDeclaration.eoriNumber
      this.taxIDType = this.customsDeclaration.taxIDType
      this.taxIDNumber = this.customsDeclaration.taxIDNumber
      this.customsDeclarationId = this.customsDeclaration.id
      this.notes = this.customsDeclaration.notes
      if (this.referenceKey !== 'LABEL_TOOL') {
        this.customsItemsState = this.customsDeclaration.items.items
      }
    },
    async saveCustomsDeclaration() {
      this.customsDeclaration.signingPerson = this.signingPerson
      this.customsDeclaration.typeOfContent = this.selectedContentOfType.value
      this.customsDeclaration.noneDeliveringHandling = this.selectedReturnHandling.value
      this.customsDeclaration.items.items = this.customsItems
      this.customsDeclaration.incoterm = this.selectedIncoterm.value
      this.customsDeclaration.importerReference = this.importerReference
      this.customsDeclaration.exporterReference = this.exporterReference
      this.customsDeclaration.eoriNumber = this.eoriNumber
      this.customsDeclaration.taxIDType = this.taxIDType
      this.customsDeclaration.taxIDNumber = this.taxIDNumber
      this.customsDeclaration.notes = this.notes
      const result = await shipmentLabelService.updateCustomsDeclaration(this.customsDeclaration)
      this.customsDeclarationId = result.data.body.id
    },
    handleUpdateItem() {
      this.saveCustomsDeclaration()
    },
    openInventorySearch() {
      if (!this.storeId) {
        Notify.error('Please select a store.')
        return
      }
      this.inventorySearchCompId = uuidv4()
    },
    handleProductSelected(payload) {
      this.addCustomsItem(payload)
    },
  },

}
</script>

<style lang="scss" scoped>
.custom-border {
  border-bottom: 0.5px solid rgb(68, 77, 85);
  margin-bottom: 6px;
  padding-bottom: 3px;
}
.custom-border:last-child {
  border-bottom: none;
}
</style>
