<template>
  <div>
    <div v-if="!isEditable">
      <div class="px-1 d-flex align-items-center">
        <div class="flex-grow-1" style="font-size: larger; font-weight: 600;">
          {{ item.description }}
        </div>
        <div class="text-button" role="button" @click="removeCustomsItem(item)">
          Remove
        </div>
        <div class="text-button" role="button" @click="openProductForm">
          Edit
        </div>
      </div>
      <div class="px-1">
        <table width="100%">
          <tr>
            <td>
              <span class="text-muted">
                Weight
              </span>
              <span :class="item.weight === 0 ? 'bg-danger px-1 round' : ''">
                {{ item.weight }} {{ item.weightUnit }}
              </span>
            </td>
            <td>
              <span class="text-muted">
                Country of Origin
              </span>
              <span :class="item.countryOfOrigin === '' ? 'bg-danger px-1 round' : ''">
                {{ item.countryOfOrigin }}
              </span>
            </td>
            <td>
              <span class="text-muted">
                Tariff Number (HS)
              </span>
              <span>
                {{ item.harmonizationCode }}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="text-muted">
                Value
              </span>
              <span>
                {{ item.value }} {{ item.moneyUnit }}
              </span>
            </td>
            <td>
              <span class="text-muted">
                Quantity
              </span>
              <span>
                {{ item.quantity }}
              </span>
            </td>
            <td>
              <span class="text-muted">
                Total Value
              </span>
              <span>
                {{ item.quantity * item.value }} {{ item.moneyUnit }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else>
      <dx-util-validation-group ref="customsItemValidationRef">
        <dx-util-text-box v-model="itemForm.description" label="Description" placeholder="Enter title">
          <dx-util-validator>
            <dx-util-required-rule message="Description is required" />
          </dx-util-validator>
        </dx-util-text-box>
        <div class="d-flex d-flex-row-with-gap">
          <dx-util-number-box v-model="itemForm.weight" class="w-50" label="Weight" placeholder="Enter weight" format="#0.0">
            <dx-util-validator>
              <dx-util-required-rule message="Weight is required" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-select-box v-model="itemForm.weightUnit" :items="weightUnits"
            label="Unit"
            class="w-50"
          />
          <dx-util-select-box v-model="itemForm.countryOfOrigin" :items="countriesDetail"
            value-expr="code" display-expr="name"
            label="Country of Origin" placeholder="Enter country"
            :search-enabled="true" class="w-100"
            >
            <dx-util-validator>
              <dx-util-required-rule message="Country of origin is required" />
            </dx-util-validator>
          </dx-util-select-box>
          <dx-util-text-box v-model="itemForm.harmonizationCode" class="w-100" label="Tariff Number (HS)" placeholder="Enter code" />
        </div>
        <div class="d-flex d-flex-row-with-gap">
          <dx-util-number-box v-model="itemForm.value" class="w-50" label="Value" placeholder="Enter value" format="#0.00">
            <dx-util-validator>
              <dx-util-required-rule message="Value is required" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-select-box v-model="itemForm.moneyUnit" :items="moneyUnits"
            label="Unit"
            class="w-50"
            :read-only="true"
          />
          <dx-util-number-box v-model="itemForm.quantity" class="w-100" label="Quantity" placeholder="Enter quantity">
            <dx-util-validator>
              <dx-util-required-rule message="Quantity is required" />
            </dx-util-validator>
          </dx-util-number-box>
          <dx-util-number-box v-model="formTotalValue" class="w-100" label="Total Value" :read-only="true" format="#0.00" />
        </div>
        <div class="d-flex pt-0 mt-1 px-half">
          <div class="w-50 pr-half">
            <dx-util-button class="d-block d-inline" type="danger" text="Cancel" @click="closeCustomsItemForm" />
          </div>
          <div class="w-50 pl-half">
            <dx-util-button class="d-block d-inline" type="success" text="Save Charges" @click="saveCustomsItemForm" />
          </div>
        </div>
      </dx-util-validation-group>
    </div>
  </div>
</template>

<script>
import { countriesDetail } from '@/http/requests/statics/locations'
import useCustomsItems from './useCustomsItems'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEditable: false,
      customsItemValidationRef: 'targetGroup1',
      countriesDetail,
      weightUnits: ['lb', 'oz', 'kg', 'gr'],
      moneyUnits: ['USD', 'CAD', 'MXN'],
      itemForm: {
        description: '',
        quantity: 0,
        weight: 0,
        weightUnit: '',
        value: 0,
        moneyUnit: '',
        countryOfOrigin: '',
        sku: '',
        harmonizationCode: '',
        eccnEarn99: '',
        orderId: '',
        fbmOrderId: '',
        productId: '',
        storeId: null,
      },
    }
  },
  computed: {
    totalValue() {
      return this.item.value * this.item.quantity
    },
    formTotalValue() {
      return this.itemForm.value * this.itemForm.quantity
    },
  },
  setup() {
    const { removeCustomsItem, updateCustomsItem } = useCustomsItems()
    return {
      removeCustomsItem, updateCustomsItem,
    }
  },
  watch: {
    formSaved: {
      immediate: true,
      deep: true,
      handler() {
        this.isEditable = false
      },
    },
  },
  methods: {
    closeCustomsItemForm() {
      this.isEditable = false
    },
    resetForm() {
      this.itemForm.description = ''
      this.itemForm.weight = ''
      this.itemForm.weightUnit = 'lb'
      this.itemForm.value = ''
      this.itemForm.moneyUnit = ''
      this.itemForm.sku = ''
      this.itemForm.eccnEarn99 = ''
      this.itemForm.orderId = ''
      this.itemForm.fbmOrderId = ''
      this.itemForm.productId = ''
      this.itemForm.storeId = ''
      this.itemForm.harmonizationCode = ''
      this.itemForm.countryOfOrigin = 'USA'
    },
    saveCustomsItemForm() {
      this.updateCustomsItem(this.itemForm)
      this.isEditable = false
      this.$emit('emit-update-item', this.itemForm)
    },
    openProductForm() {
      this.isEditable = true
      this.itemForm.description = this.item.description
      this.itemForm.quantity = this.item.quantity
      this.itemForm.weight = this.item.weight
      this.itemForm.weightUnit = this.item.weightUnit
      this.itemForm.value = this.item.value
      this.itemForm.moneyUnit = this.item.moneyUnit
      this.itemForm.countryOfOrigin = this.item.countryOfOrigin
      this.itemForm.sku = this.item.sku
      this.itemForm.harmonizationCode = this.item.harmonizationCode
      this.itemForm.eccnEarn99 = this.item.eccnEarn99
      this.itemForm.orderId = this.item.orderId
      this.itemForm.fbmOrderId = this.item.fbmOrderId
      this.itemForm.productId = this.item.productId
      this.itemForm.storeId = this.item.storeId
    },
  },
}
</script>

<style lang="scss" scoped>
.text-button {
  color: #437EB4;
  margin-left: 16px;
  transition: color 0.3s ease;
}
.text-button:hover {
  color: #66bcf6;
}
.d-flex-row-with-gap > div {
    margin-right: 8px; /* Adjust the margin to create the desired gap */
  }

  .d-flex-row-with-gap > div:last-child {
    margin-right: 0; /* Remove margin from the last child to prevent extra space */
  }

</style>
